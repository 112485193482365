/* class styles */
.main-site-container {
  display: flex;
  flex-direction: column;
  background-color: var(--detail);
}

.subsection-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 75vh;
  padding: 3rem 3rem;
}

.subheading {
  color: var(--primary-bg);
  font-size: 1.75rem;
}

.subsection-container h3::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  margin: 1rem 0;
  background: var(--accent);
}

.info-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  margin-top: 1rem;
}

.icon {
  width: 20px;
  height: 20px;
}

.info-link:hover {
  filter: opacity(0.5);
}

/* header styles */
#header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);
  width: 100vw;
  min-height: 100vh;
}

#header-name {
  font-size: 4rem;
  color: var(--neutral-bg);
  margin: 0 3rem;
}

.header-desc {
  font-family: var(--secondary-font);
  color: var(--neutral-bg);
  margin: 1rem 3rem;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

#header-container div {
  margin: 2rem 0;
}

/* about subsection */
#about-text {
  margin: 1rem 0;
  font-family: var(--secondary-font);
}

#portrait-education-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

#portrait-container {
  width: 50%;
  margin: 1rem 0;
}

#portrait-container img {
  width: 100%;
  max-width: 600px;
  height: auto;
  object-fit: cover;
  object-position: center;
  border: 3px solid var(--accent);
}

#education-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

#school-grad-container {
  display: flex;
  justify-content: space-between;
}

#degree {
  font-size: 1.25rem;
  color: var(--primary-bg);
  margin-top: 1rem;
}

#school, #cognate {
  font-size: 1.25rem;
  color: var(--primary-bg);
}

#graduation-container {
  display: flex;
  justify-content: flex-end;
  color: var(--primary-bg)
}

#city-container, #linkedin-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

#email-container, #github-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 0.5rem;
}

/* skills subsection */
#skills-container {
  background-color: var(--neutral-bg);
}

#skills-text {
  margin-bottom: 1rem;
  font-family: var(--secondary-font);
}

.progress-container {
  margin-bottom: 1rem;
}

.skill-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
  margin-bottom: 0.5rem;
}

.progress-bar {
  width: 100%;
  background-color: var(--neutral-bg);
  border: 1px solid var(--accent);
  border-radius: 4px;
}

.progress {
  height: 20px;
  background-color: var(--primary-bg);
  transition: width 0.5s;
  border-radius: 3px;
}

.skill, .level {
  font-family: var(--secondary-font);
}

/* experience subsection */
.experience-container * {
  font-family: var(--secondary-font);
}

.experience-container {
  padding: 0 0 1rem 1rem;
  border-left: 2px solid var(--primary-bg);
}

.experience-title {
  color: var(--primary-bg);
  margin-bottom: 0.5rem;
}

.experience-container::before {
  content: "";
  position: absolute;
  left: 42px;
  width: 11px;
  height: 11px;
  border-radius: 50px;
  background: var(--detail);
  border: 2px solid var(--primary-bg);
}

.experience-subtitle {
  margin-bottom: 0.5rem;
}

.experience-date {
  margin-bottom: 0.5rem;
}

.experience-list {
  margin-left: 2rem;
}

.experience-bullet {
  font-size: 12px;
}


/* projects subsection */
#projects-container {
  background-color: var(--neutral-bg);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000e1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-modal {
  max-width: 90vw;
  max-height: 80vh;
  background-color: var(--primary-bg);
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.modal-button {
  margin-top: 20px;
  padding: 10px 40px;
}

.modal-title {
  color: #fff;
  font-family: var(--secondary-font);
  margin: 1rem 0;
}

.modal-text {
  color: #fff;
  font-family: var(--secondary-font);
  margin: 1rem 0;
}

.project-button-container {
  width: clamp(50%, 700px, 90%);
  height: min(50%, 300px);
}

.project-button-container:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 2px solid var(--primary-bg);
}

.project-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.project-button-title {
  color: var(--primary-bg);
  font-family: var(--secondary-font);
  margin-bottom: 0.5rem;
}

#projects-intro {
  margin: 1.5rem 0;
  font-family: var(--secondary-font);
}

.project-break {
  width: 50vw;
  border: none;
  height: 2px;
  background-color: var(--primary-bg);
  margin: 1rem 0;
}

/* media queries */
@media screen and (max-width: 768px) {
  #header-name {
    font-size: 2.5rem;
  }
  
  #header-desc {
    font-size: 1.75rem;
  }

  #portrait-education-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  #portrait-container {
    height: 50%;
    width: 80%;
    margin: 1rem 0;
  }
  
  #education-container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-height: 500px) {
  .modal-title {
    font-size: 14px;
  }
  
  .modal-text {
    font-size: 12px;
  }
}

@media screen and (max-height: 400px) {
  .modal-title {
    font-size: 14px;
  }
  
  .modal-text {
    font-size: 10px;
  }
}